import * as Types from '../../../../types/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InquiriesStylingFormInquiryFormFragment = { __typename?: 'InquiryForm', instructions?: string | null, fields?: Array<{ __typename?: 'InquiryFormField', order: number, type: Types.InquiryFormFieldType, label: string, required: boolean, services?: any | null, multiple?: boolean | null }> | null, styles?: Array<{ __typename?: 'InquiryFormStyle', type: Types.InquiryFormStyleType, value: string }> | null };

export type InquiriesStylingForm_UpdateInquiryFormMutationVariables = Types.Exact<{
  data: Types.InquiryFormDataInput;
}>;


export type InquiriesStylingForm_UpdateInquiryFormMutation = { __typename?: 'Mutation', updateInquiryForm: { __typename?: 'InquiryForm', instructions?: string | null, fields?: Array<{ __typename?: 'InquiryFormField', order: number, type: Types.InquiryFormFieldType, label: string, required: boolean, services?: any | null, multiple?: boolean | null }> | null, styles?: Array<{ __typename?: 'InquiryFormStyle', type: Types.InquiryFormStyleType, value: string }> | null } };

export const InquiriesStylingFormInquiryFormFragmentDoc = gql`
    fragment inquiriesStylingFormInquiryForm on InquiryForm {
  instructions
  fields {
    order
    type
    label
    required
    services
    multiple
  }
  styles {
    type
    value
  }
}
    `;
export const InquiriesStylingForm_UpdateInquiryFormDocument = gql`
    mutation InquiriesStylingForm_UpdateInquiryForm($data: InquiryFormDataInput!) {
  updateInquiryForm(data: $data) {
    ...inquiriesStylingFormInquiryForm
  }
}
    ${InquiriesStylingFormInquiryFormFragmentDoc}`;
export type InquiriesStylingForm_UpdateInquiryFormMutationFn = Apollo.MutationFunction<InquiriesStylingForm_UpdateInquiryFormMutation, InquiriesStylingForm_UpdateInquiryFormMutationVariables>;

/**
 * __useInquiriesStylingForm_UpdateInquiryFormMutation__
 *
 * To run a mutation, you first call `useInquiriesStylingForm_UpdateInquiryFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInquiriesStylingForm_UpdateInquiryFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inquiriesStylingFormUpdateInquiryFormMutation, { data, loading, error }] = useInquiriesStylingForm_UpdateInquiryFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInquiriesStylingForm_UpdateInquiryFormMutation(baseOptions?: Apollo.MutationHookOptions<InquiriesStylingForm_UpdateInquiryFormMutation, InquiriesStylingForm_UpdateInquiryFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InquiriesStylingForm_UpdateInquiryFormMutation, InquiriesStylingForm_UpdateInquiryFormMutationVariables>(InquiriesStylingForm_UpdateInquiryFormDocument, options);
      }
export type InquiriesStylingForm_UpdateInquiryFormMutationHookResult = ReturnType<typeof useInquiriesStylingForm_UpdateInquiryFormMutation>;
export type InquiriesStylingForm_UpdateInquiryFormMutationResult = Apollo.MutationResult<InquiriesStylingForm_UpdateInquiryFormMutation>;
export type InquiriesStylingForm_UpdateInquiryFormMutationOptions = Apollo.BaseMutationOptions<InquiriesStylingForm_UpdateInquiryFormMutation, InquiriesStylingForm_UpdateInquiryFormMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    