import * as Types from '../../../types/generated';

import { gql } from '@apollo/client';
import { InquiriesConfigurationFieldsInquiryFormFragmentDoc } from '../InquiriesConfigurationForm/__generated__/useInquiriesConfigurationFields';
import { InquiriesStylingFormInquiryFormFragmentDoc } from '../InquiriesStylingForm/__generated__/index';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InquiriesConfigurationInquiryFormFragment = { __typename?: 'InquiryForm', instructions?: string | null, fields?: Array<{ __typename?: 'InquiryFormField', order: number, type: Types.InquiryFormFieldType, label: string, required: boolean, services?: any | null, multiple?: boolean | null }> | null, styles?: Array<{ __typename?: 'InquiryFormStyle', type: Types.InquiryFormStyleType, value: string }> | null };

export type InquiriesConfiguration_GetInquiryFormQueryVariables = Types.Exact<{
  where: Types.OrganizationWhereUniqueInput;
}>;


export type InquiriesConfiguration_GetInquiryFormQuery = { __typename?: 'Query', getInquiryForm?: { __typename?: 'InquiryForm', instructions?: string | null, fields?: Array<{ __typename?: 'InquiryFormField', order: number, type: Types.InquiryFormFieldType, label: string, required: boolean, services?: any | null, multiple?: boolean | null }> | null, styles?: Array<{ __typename?: 'InquiryFormStyle', type: Types.InquiryFormStyleType, value: string }> | null } | null };

export const InquiriesConfigurationInquiryFormFragmentDoc = gql`
    fragment inquiriesConfigurationInquiryForm on InquiryForm {
  ...inquiriesConfigurationFieldsInquiryForm
  ...inquiriesStylingFormInquiryForm
}
    ${InquiriesConfigurationFieldsInquiryFormFragmentDoc}
${InquiriesStylingFormInquiryFormFragmentDoc}`;
export const InquiriesConfiguration_GetInquiryFormDocument = gql`
    query InquiriesConfiguration_GetInquiryForm($where: OrganizationWhereUniqueInput!) {
  getInquiryForm(where: $where) {
    ...inquiriesConfigurationInquiryForm
  }
}
    ${InquiriesConfigurationInquiryFormFragmentDoc}`;

/**
 * __useInquiriesConfiguration_GetInquiryFormQuery__
 *
 * To run a query within a React component, call `useInquiriesConfiguration_GetInquiryFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useInquiriesConfiguration_GetInquiryFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInquiriesConfiguration_GetInquiryFormQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useInquiriesConfiguration_GetInquiryFormQuery(baseOptions: Apollo.QueryHookOptions<InquiriesConfiguration_GetInquiryFormQuery, InquiriesConfiguration_GetInquiryFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InquiriesConfiguration_GetInquiryFormQuery, InquiriesConfiguration_GetInquiryFormQueryVariables>(InquiriesConfiguration_GetInquiryFormDocument, options);
      }
export function useInquiriesConfiguration_GetInquiryFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InquiriesConfiguration_GetInquiryFormQuery, InquiriesConfiguration_GetInquiryFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InquiriesConfiguration_GetInquiryFormQuery, InquiriesConfiguration_GetInquiryFormQueryVariables>(InquiriesConfiguration_GetInquiryFormDocument, options);
        }
export type InquiriesConfiguration_GetInquiryFormQueryHookResult = ReturnType<typeof useInquiriesConfiguration_GetInquiryFormQuery>;
export type InquiriesConfiguration_GetInquiryFormLazyQueryHookResult = ReturnType<typeof useInquiriesConfiguration_GetInquiryFormLazyQuery>;
export type InquiriesConfiguration_GetInquiryFormQueryResult = Apollo.QueryResult<InquiriesConfiguration_GetInquiryFormQuery, InquiriesConfiguration_GetInquiryFormQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    