import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import styles from './MuiTipTap.module.scss';
import { Box } from '@mui/material';
import useRichTextEditor, { UseRichTextEditorProps } from './useRichTextEditor';
import { Editor, EditorContent } from '@tiptap/react';
import MuiTipTapBubbleMenu from './MuiTipTapMenu/MuiTipTapBubbleMenu';
import MuiTextArea, { MuiTextAreaProps } from '../MuiTextArea/MuiTextArea';
import { useDeviceDetection } from '../../hooks/useDeviceDetection';
import MuiTipTapMobileMenu from './MuiTipTapMenu/MuiTipTapMobileMenu';
import useErrorScrollContext from '../../hooks/useErrorScrollContext';

export type MuiTipTapProps = {
	value?: UseRichTextEditorProps['content'];
	onChange?: ( html: string, text: string ) => void;
	countCharacters?: boolean;
	bubbleMenu?: boolean;
	onFocus?: () => void;
	onBlur?: () => void;
} & Omit<
MuiTextAreaProps,
'value' | 'onChange' | 'countCharacters' | 'onFocus' | 'onBlur'
>;

export type MuiTipTapRef = {
	focus: () => void;
};

const MuiTipTap = forwardRef<MuiTipTapRef, MuiTipTapProps>( ( props, ref ) => {
	const {
		onChange,
		value,
		className,
		countCharacters,
		bubbleMenu,
		inputProps,
		...propsToSpread
	} = props;
	const innerRef = useErrorScrollContext( !!propsToSpread.errorText );
	const { [ 'data-cy' ]: dataCy, ...inputPropsRest } = inputProps || {};
	const [ focused, setFocused ] = useState( false );
	const [ isReady, setIsReady ] = useState( false );
	const tipTapFocusedRef = useRef( false );
	const mobileMenuFocusedRef = useRef( false );
	const handleOnFocus = useCallback(
		( isMobileMenu: boolean ) => {
			setFocused( true );
			if ( isMobileMenu ) {
				mobileMenuFocusedRef.current = true;
			} else {
				tipTapFocusedRef.current = true;
			}
			/* some casting to force the tip-tap event to be the MUI event
			Not necessarily "legit" but should be mostly correct */
			propsToSpread.onFocus?.();
		},
		[ propsToSpread ]
	);
	/* If the user clicks on a button in the mobile menu
	1. tip-tap blurs
	2. then the button gets focus
	This can be a problem because we can tell the parent that this container component
	has "blurred" when really it kinda hasn't. So we'll wait a beat for that second
	event to possibly fire before we tell the world that we've been blurred */
	const informParentOfBlurAfterDelay = useCallback(
		() =>
			setTimeout( () => {
				// only blur if both tip-tap and it's mobile menu are blurred
				if ( !mobileMenuFocusedRef.current && !tipTapFocusedRef.current ) {
					setFocused( false );
					/* some casting to force the tip-tap event to be the MUI event
					Not necessarily "legit" but should be mostly correct */
					propsToSpread.onBlur?.();
				}
			}, 0 ),
		[ propsToSpread ]
	);
	const handleOnBlur = useCallback(
		( isMobileMenu: boolean ) => {
			if ( isMobileMenu ) {
				mobileMenuFocusedRef.current = false;
			} else {
				tipTapFocusedRef.current = false;
			}
			informParentOfBlurAfterDelay();
		},
		[ informParentOfBlurAfterDelay ]
	);
	const editor = useRichTextEditor( {
		limit: propsToSpread.maxLength,
		editable: !propsToSpread.disabled,
		placeholder: propsToSpread.placeholder,
		onCreate: () => setIsReady( true ),
		onFocus: () => handleOnFocus( false ),
		onBlur: () => handleOnBlur( false ),
		// although this only "takes in" as a value a html string, I'm exposing the text in addition to the html in case it's useful
		onUpdate: ( { editor } ) => onChange?.( editor.getHTML(), editor.getText() ),
	} );
	// Make a queue so if we want to do something with the editor and it's not ready yet, we can wait
	const onReadyCallbacks = useRef<( ( editor: Editor ) => void )[]>( [] );
	// useImperativeHandle so we can access this editor ref from the parent, so we can focus on it
	useImperativeHandle(
		ref,
		(): MuiTipTapRef => ( {
			focus: async () => {
				if ( !editor ) {
					onReadyCallbacks.current.push( ( editor ) => editor.view.focus() );
				} else {
					editor?.view.focus();
				}
			},
		} ),
		[ editor ]
	);
	// Now handle the onReady callbacks when we're ready
	useEffect( () => {
		if ( isReady && editor ) {
			/* if we want to access the TipTap proseMirror - the <div contenteditable>...</div> that
			is the tiptap DOM element - this'll let us do it in the same way as we do it for
			MuiTextField, like so
			inputProps={ { 'data-cy': 'whatever value you want' } }
			*/
			if ( dataCy ) {
				editor.view.dom.setAttribute( 'data-cy', dataCy );
			}
			while ( onReadyCallbacks.current.length ) {
				const callback = onReadyCallbacks.current.pop();
				callback?.( editor );
			}
		}
	}, [
		isReady,
		editor,
		dataCy
	] );
	// and handle when value changes via props
	useEffect( () => {
		if ( value ) {
			if ( isReady && editor ) {
				// we can loose the first space bar click if we set state _always_ here
				if ( value !== editor.getHTML() ) {
					editor.commands.setContent( value, false );
				}
			} else {
				onReadyCallbacks.current.push( ( editor ) =>
					editor.commands.setContent( value, false )
				);
			}
		}
	}, [
		value,
		isReady,
		editor
	] );
	/* strip paragraphs from the markup as newlines, then strip other markup
	Using getText caused problems with newlines, including sequential newlines, and character counting */
	const convertEditorValueToTextAreaValue = useCallback(
		() =>
			editor
				?.getHTML()
				.replaceAll( /<\/p><p>/g, '\n' )
				/* a fake character so that if they just start a list and don't type anything, that still
				 counts as "content" in MUI's eyes */
				.replaceAll( /<(u|o)l>/g, '-' )
				.replaceAll( /<\/?[^>]+>/g, '' ) || '',
		[ editor ]
	);
	const minHeight = editor
		? `calc( ${ editor.view.dom.getBoundingClientRect().height }px - 2.2rem`
		: undefined;
	const { isTouch } = useDeviceDetection();
	const useBubbleMenu = !!bubbleMenu && !isTouch && !propsToSpread.disabled;
	return (
		<Box
			className={ [
				styles.container,
				...( useBubbleMenu ? [ styles.usingBubbleMenu ] : [] ),
				...( propsToSpread.disabled ? [ styles.disabled ] : [] ),
				...( propsToSpread.fullWidth ? [ styles.fullWidth ] : [] ),
				className,
			].join( ' ' ) }
		>
			<MuiTextArea
				{ ...propsToSpread }
				/* tip-tap doesn't count creating new paragraphs/hitting the enter key as a character
				so technically someone could put a gigabyte value into a news field by just hitting a ton of enter keys */
				countCharacters={
					countCharacters
						? editor?.storage.characterCount.characters()
						: undefined
				}
				focused={ focused }
				className={ styles.muiTextArea }
				value={ convertEditorValueToTextAreaValue() }
				inputProps={ {
					...inputPropsRest,
					style: { minHeight },
				} }
				ref={ innerRef }
			/>
			<Box className={ styles.tipTap }>
				<MuiTipTapBubbleMenu
					editor={ editor }
					shouldShow={ !useBubbleMenu ? () => useBubbleMenu : undefined }
					onFocus={ () => handleOnFocus( true ) }
					onBlur={ () => handleOnBlur( true ) }
				/>
				<Box
					className={ styles.textarea }
					component={ EditorContent }
					editor={ editor }
				/>
			</Box>
			{ !useBubbleMenu && !propsToSpread.disabled ? (
				<MuiTipTapMobileMenu
					editor={ editor }
					onFocus={ () => handleOnFocus( true ) }
					onBlur={ () => handleOnBlur( true ) }
				/>
			) : null }
		</Box>
	);
} );
MuiTipTap.displayName = 'MuiTipTap';

export default MuiTipTap;
