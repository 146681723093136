import {
	InquiryFormField,
	InquiryFormFieldType,
} from './../../../types/generated';
import { RPCShared } from '@rockpapercoin/rpc-shared';

type Field = RPCShared.Inquiries.Field;

export enum Columns {
	Both = 'Both',
	Left = 'Left',
	Right = 'Right',
}

export type MutableProps = {
	setRequired: ( value: boolean ) => void;
	setLabel: ( value: string ) => void;
	setServices: ( value?: string[] ) => void;
	setMultiple: ( value?: boolean ) => void;
};

export type CommonFieldValues = Pick<
InquiryFormField,
'order' | 'label' | 'required' | 'services' | 'multiple'
> & { included: boolean };

export type CommonFieldData = Field & CommonFieldValues;

export type NotFirstNameField = CommonFieldData & {
	column: Columns;
	validation: string | undefined;
};
export type SecondNameField = NotFirstNameField & {
	lastNameTwo: Pick<InquiryFormField, 'type' | 'label' | 'required'>;
};
export type CommonField = ( SecondNameField | NotFirstNameField ) & MutableProps;

export const commonFieldDataIsFirstNameTwo = (
	field: SecondNameField | NotFirstNameField | undefined
): field is SecondNameField =>
	field?.type === InquiryFormFieldType.FirstNameTwo;
