import { IconButton, ListItem, ListItemText } from '@mui/material';
import React from 'react';
import Icon from '../../../../../../elements/icons';
import MuiTextFieldText from '../../../../../../mui/MuiTextField/MuiTextFieldText/MuiTextFieldText';
import styles from './ServiceNameField.module.scss';

export type ServiceNameFieldProps = {
	id: string;
	value: string;
	onChange: ( id: string, value: string ) => void;
	onDelete?: ( id: string ) => void;
};

export const ServiceNameField: React.FC<ServiceNameFieldProps> = ( {
	id,
	value,
	onChange,
	onDelete,
} ) => (
	<ListItem
		disableGutters
		secondaryAction={
			onDelete ? (
				<IconButton onClick={ () => onDelete( id ) }>
					<Icon type='trash' className={ styles.icon } />
				</IconButton>
			) : undefined
		}
	>
		<ListItemText>
			<MuiTextFieldText
				fullWidth
				label='Service name'
				value={ value }
				onChange={ ( e ) => onChange( id, e.target.value ) }
				className={ onDelete ? styles.input : undefined }
				inputProps={ { 'data-cy': 'service-name' } }
			/>
		</ListItemText>
	</ListItem>
);
