import { debounce } from 'lodash';
import Query from '../Query';
import Globals from '../Globals';
import searchAPI from './search';
import {
	ProfileImageFields,
	ContactScalarFields,
	OrgReturnFields,
	UserReturnFields,
	UserDetailReturnFields,
	OrgUserReturnFields,
	ClientUserReturnFieldsWithPermissions,
	ClientUserDetailReturnFields,
	OrgUserWithClientsReturnFields,
	SinglePaymentSourceReturnFields,
	StripeUserReturnFields,
	InvitationReturnFields,
	ReducedCouponReturnFields,
	CouponReturnFields,
	EventReturnFields,
	EventsNotHandled,
	EventsNotHandledForClient,
	ContactWithIdentifiersReturnFields,
	OfflineTransactionHistory,
	OrgNameReturnFields,
	InvoiceNameReturnFields,
	ContractTemplateNameReturnFields,
	ContractNameReturnFields,
	ResourceNameReturnFields,
	GetDocumentNameReturnFields,
	GetFolderNameReturnFields,
} from './returnFields/index';

//request
import * as requestAPI from './request';
//auth
import * as authAPI from './auth';
//users
import * as userAPI from './users/user';
import * as clientUserAPI from './users/client';
import * as orgUserAPI from './users/org';
import * as superAdminAPI from './users/superAdmin';
//organization
import * as organizationAPI from './organization';
//contracts
import * as contractsAPI from './contracts';
//invoice
import * as invoiceAPI from './invoices';
// folders
import * as folderAPI from './folders';
// resources
import * as resourceAPI from './resources';
//stripe
import * as stripeAPI from './stripe';

import * as fileAPI from './file';

import * as contactsAPI from './contacts';

import * as proposalsAPI from './proposals';

import * as typedAPI from './typed';

import * as invitationsAPI from './invitations';

export const filePurposes = {
	profileImage: 'profileImage',
	stripeVerificationDocument: 'stripeVerificationDocument',
	invoiceDocumentation: 'invoiceDocumentation',
	unsignedContract: 'unsignedContract',
	contractTemplate: 'contractTemplate',
	signedContract: 'signedContract',
};

const API = {
	filePurposes,
	//request
	...requestAPI,
	//auth
	...authAPI,
	//users
	...userAPI,
	...clientUserAPI,
	...orgUserAPI,
	...superAdminAPI,
	//organization
	...organizationAPI,
	//contracts
	...contractsAPI,
	//invoice
	...invoiceAPI,
	//folder
	...folderAPI,
	//stripe
	...stripeAPI,
	//resources
	...resourceAPI,
	//file
	...fileAPI,
	//contacts
	...contactsAPI,
	//proposals
	...proposalsAPI,
	//search general-handler
	...searchAPI,
	//typed
	...typedAPI,
	// invitations
	...invitationsAPI,
	/**
	 * Checks to see if a given email has been used to create an account before.
	 *
	 * @param {string} email - Email to check.
	 *
	 * @returns { Promise<{ available: boolean } | { errors: string[] }> }
	 */
	async isEmailAvailable( email ) {
		const { data, errors } = await this.request(
			new Query( {
				type: 'query',
				name: 'isEmailAvailable',
				params: { email },
			} )
		);

		if ( errors ) {
			return { errors };
		}

		return {
			available: data && data.data && data.data.isEmailAvailable,
		};
	},
	/** @typedef { Array<{ category: string, active: boolean }> } FeatureFlags */
	/**
	 * Returns a list of feature flags, their category and current status
	 *
	 * @returns { Promise< { errors: Array<Error> } | { featureFlags: FeatureFlags } > }
	 */
	async getFeatureFlags() {
		const { data, errors } = await this.request(
			new Query( {
				type: 'query',
				name: 'getFeatureFlags',
				params: null,
				returnFields: [ 'category', 'active' ],
			} )
		);

		if ( errors ) {
			return { errors };
		}

		if ( data ) {
			return { featureFlags: data.data.getFeatureFlags };
		}
	},
	/**
	 * As advertized: gets GlobalVariables table data
	 * @returns { Promise<
	 *   { errors: Array<Error> } |
	 *   { globalVariables: {
	 *     MaxFoldersPerLayer: number,
	 *     TGIntegrationURL: string,
	 *     TGLogo: string,
	 *     AutomatedNotificationsSupportUrl: string,
	 *     StripeLegalUrl: string,
	 *     TermsOfServiceUrl: string,
	 *     HelpUrl: string,
	 *     PlannerPermissionsHelpUrl: string
	 * } }> }
	 */
	async getGlobalVariables() {
		const { data, errors } = await this.request(
			new Query( {
				type: 'query',
				name: 'getGlobalVariables',
				params: null,
				returnFields: [
					'MaxFoldersPerLayer',
					'TGIntegrationURL',
					'TGLogo',
					'AutomatedNotificationsSupportUrl',
					'StripeLegalUrl',
					'TermsOfServiceUrl',
					'HelpUrl',
					'PlannerPermissionsHelpUrl',
				],
			} )
		);

		if ( errors ) {
			return { errors };
		}

		if ( data ) {
			return { globalVariables: data.data.getGlobalVariables };
		}
	},

	/**
	 * Requests a history of offline payments for a specific organization
	 *
	 * @param { String } orgID - the id of the organization for which to request a history
	 * @param { Object | null } after - an object containing the ids to search after
	 * @param { String } after.paymentAfter - the id of the paid installment to search after
	 * @param { String } after.refundAfter - the id of the refunded installment to search after
	 *
	 * @returns { Promise<{ errors: Error[] } | { offlineHistory: any, hasMore: boolean }> } contains historyItems, hasMore, if successfully retrieved, and errors, if occurring
	 */
	async getOrgOfflineTransactionHistory( orgID, after, limit = 10 ) {
		const params = {
			where: { id: orgID },
			take: limit + 1,
		};
		if ( after ) params.after = after;

		const { errors, data } = await this.request(
			new Query( {
				type: 'query',
				params,
				name: 'getOrgOfflineTransactionHistory',
				returnFields: OfflineTransactionHistory,
			} )
		);
		if ( errors ) {
			return { errors };
		}
		const offlineHistory =
			data.data.getOrgOfflineTransactionHistory.offlinePaymentHistoryItems;
		let hasMore = false;
		if ( offlineHistory.length > limit ) {
			offlineHistory.pop();
			hasMore = true;
		}
		return {
			offlineHistory,
			hasMore,
		};
	},

	/**
	 * Requests a history of offline payments for a specific client user.
	 *
	 * @param { String } userId - the id of the client for which to request a history
	 * @param { Object | null } after - an object containing the ids to search after
	 * @param { String } after.paymentAfter - the id of the paid installment to search after
	 * @param { String } after.refundAfter - the id of the refunded installment to search after
	 *
	 * @returns { Promise<{ errors: Error[] } | { offlineHistory: any, hasMore: boolean }> } contains historyItems, hasMore, if successfully retrieved, and errors, if occurring
	 */
	async getCustomerOfflineTransactionHistory( userId, after, limit = 10 ) {
		const params = {
			where: { id: userId },
			take: limit + 1,
		};
		if ( after ) params.after = after;

		const { errors, data } = await this.request(
			new Query( {
				type: 'query',
				params,
				name: 'getCustomerOfflineTransactionHistory',
				returnFields: OfflineTransactionHistory,
			} )
		);
		if ( errors ) {
			return { errors };
		}
		const offlineHistory =
			data.data.getCustomerOfflineTransactionHistory.offlinePaymentHistoryItems;
		let hasMore = false;
		if ( offlineHistory.length > limit ) {
			offlineHistory.pop();
			hasMore = true;
		}
		return {
			offlineHistory,
			hasMore,
		};
	},

	// Get values for Segment page

	/**
	 * Gets the name for a provided id
	 * @param { string } id
	 * @param { string } [token]
	 * @returns { Promise<string> }
	 * */
	getContractNameWhere: async function( id, token = null ) {
		const where = { contract: { id } };

		if ( token ) {
			where.guestToken = token;
		}

		const { errors, data } = await this.request(
			new Query( {
				type: 'query',
				name: 'getContractWhere',
				params: { where },
				returnFields: ContractNameReturnFields,
			} )
		);
		if ( errors ) {
			return { errors };
		}
		return data?.data.getContractWhere.contract.title;
	},

	/**
	 * Gets the title for a provided id
	 *
	 * @param { string } id
	 *
	 * @returns { Promise<string> }
	 * */
	getOrganizationContractNameTemplate: async ( id ) => {
		const where = { id };
		const { data, errors } = await this.request(
			new Query( {
				type: 'query',
				name: 'getOrganizationContractTemplate',
				params: {
					where,
				},
				returnFields: ContractTemplateNameReturnFields,
			} )
		);
		if ( errors ) {
			return { errors };
		}

		return data.data.getOrganizationContractTemplate.title;
	},

	/**
	 * Gets the name for a provided id
	 *
	 * @param { string } id
	 * @param { string } [token]
	 *
	 * @returns { Promise<string> }
	 * */
	getInvoiceNameWhere: async function( id, token = null ) {
		const queryParams = { where: { id } };

		if ( token ) {
			queryParams.data = { token };
		}

		const { data, errors } = await this.request(
			new Query( {
				type: 'query',
				name: 'getInvoiceWhere',
				params: queryParams,
				returnFields: InvoiceNameReturnFields,
			} )
		);
		if ( errors ) return { errors };

		return data.data.getInvoiceWhere.title;
	},

	/**
	 * Gets the name for a provided id
	 *
	 * @param { string } id
	 *
	 * @returns { Promise<string> }
	 * */
	getOrganizationNameWhere: async function( id ) {
		const where = { id };
		const { data, errors } = await this.request(
			new Query( {
				type: 'query',
				name: 'getOrganizationWhere',
				params: { where },
				returnFields: OrgNameReturnFields,
			} )
		);

		if ( errors ) return { errors };

		return data.data.getOrganizationWhere.name;
	},

	/**
	 * Gets the name for a provided id
	 *
	 * @param { string } id
	 *
	 * @returns { Promise<string> }
	 * */
	getFolderNameWhere: async function( id ) {
		const params = {
			where: { id },
			orderBy: { createdAt: 'desc' },
			skipFolder: 0,
			skipDocument: 0,
			take: 0,
			folderTakeOverride: 50,
			documentTakeOverride: 50,
			batchSort: true,
		};
		const { data, errors } = await this.request(
			new Query( {
				type: 'query',
				name: 'getFolderContents',
				params,
				returnFields: GetFolderNameReturnFields,
			} )
		);

		if ( errors ) return { errors };

		return data.data.getFolderContents.name;
	},

	/**
	 * Gets the name for a provided id
	 *
	 * @param { string } id
	 *
	 * @returns { Promise<string> }
	 * */
	getDocumentNameWhere: async function( id ) {
		const where = { id };
		const { data, errors } = await this.request(
			new Query( {
				type: 'query',
				name: 'getDocumentWhere',
				params: { where },
				returnFields: GetDocumentNameReturnFields,
			} )
		);

		if ( errors ) return { errors };

		return data.data.getDocumentWhere.name;
	},

	/**
	 * Gets the description for a provided id
	 *
	 * @param { string } id
	 *
	 * @returns { Promise<string> }
	 * */
	getResourceNameWhere: async function( id ) {
		const where = { id };
		const { errors, data } = await this.request(
			new Query( {
				type: 'query',
				name: 'getResourceWhere',
				params: { where },
				returnFields: ResourceNameReturnFields,
			} )
		);

		if ( errors ) {
			return { errors };
		}

		return data?.data.getResourceWhere.description;
	},

	/**
	 * @param { string } id
	 * @param { string } [guestToken]
	 * @returns { Promise<string> }
	 * */
	getProposalNameWhere: async function( id, guestToken ) {
		const queryParams = {
			where: { proposal: { id }, ...( guestToken ? { guestToken } : undefined ) },
		};

		const { data, errors } = await this.request(
			new Query( {
				type: 'query',
				name: 'getProposalWhere',
				params: queryParams,
				returnFields: InvoiceNameReturnFields,
			} )
		);
		if ( errors ) return { errors };

		return data.data.getProposalWhere.title;
	},

	/**
	 * @param { string } id
	 * @returns { Promise<string> }
	 * */
	getProposalTemplateNameWhere: async function( id ) {
		const queryParams = { where: { id } };

		const { data, errors } = await this.request(
			new Query( {
				type: 'query',
				name: 'getProposalTemplateWhere',
				params: queryParams,
				returnFields: InvoiceNameReturnFields,
			} )
		);
		if ( errors ) return { errors };

		return data.data.getProposalTemplateWhere.title;
	},
};

API.request = API.requestWithoutToken;

// Don't allow this function to be called more than once per 5 seconds.
API.resendContract = debounce( API.resendContract, 5000, { leading: true } );
API.resendInvoice = debounce( API.resendInvoice, 5000, { leading: true } );

API.createStripeConnectAccount = async function( data, where ) {
	const { data: createData, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'createStripeConnectAccount',
			params: { data, where },
			returnFields: [ 'id' ],
		} )
	);

	if ( errors ) return { errors };

	return {
		connectAccount: createData.data.createStripeConnectAccount,
	};
};

/**
 * @param {object} user - Baseuser object.
 * @param {string} user.id - Unique identifier.
 * @param {object} dataArgs - Data args for request
 * @param {object} dataArgs.source
 * @param {string} dataArgs.source.country - Two digit country code.
 * @param {string} dataArgs.source.currency - Required field for OrgUser/connect accounts.
 * @param {string} dataArgs.source.account_holder_name - Name of account holder.
 * @param {string} dataArgs.source.account_holder_type - "individual" | "company"
 * @param {string} dataArgs.source.routing_number - Account routing number.
 * @param {string} dataArgs.source.account_number - Account number.
 * @param {string} dataArgs.nickname - Nickname of the account.
 * @param {boolean} dataArgs.isDefault - Whether or not to make this the default pay-from account.
 */
API.createBankAccountForUser = function( user, dataArgs ) {
	const data = {
		...dataArgs,
	};
	data.source.object = 'bank_account';
	data.source.currency = 'usd';

	const where = {
		id: user.id,
	};
	return this.createCustomerStripeBankAccount( where, data );
};

/**
 * Queries the API to return a Stripe bank account token from a Plaid public token and account ID
 *
 * @param { Object } data
 * @param { String } data.plaidToken - Plaid public token
 * @param { String } data.accountID - Plaid bank account ID
 *
 */
API.getStripeTokenFromPlaidToken = async function( data ) {
	const { data: res, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getStripeTokenFromPlaidToken',
			params: { data },
		} )
	);

	if ( errors ) {
		return { errors };
	}

	return { stripeToken: res.data.getStripeTokenFromPlaidToken };
};

/**
 * Send a mutation to the API create a bank account for a client using Plaid
 *
 * @param { String } userID - The client user's Id
 * @param { Object} data
 * @param { String } data.token - Plaid public token
 * @param { String } data.accountID - Plaid bank account ID
 *
 */
API.createPlaidBankAccount = async function( userID, data ) {
	const { data: res, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'createCustomerPlaidBankAccount',
			params: { where: { id: userID }, data },
			returnFields: SinglePaymentSourceReturnFields,
		} )
	);

	if ( errors ) {
		return { errors };
	}

	return { source: res.data.createCustomerPlaidBankAccount };
};

/**
 * Returns the StripeUser associated with a ClientUser
 *
 * @param {String} id - Client user's ID.
 *
 * @returns {Object} res - { errors, StripeUser }
 */
API.getStripeCustomer = async function( id ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getStripeCustomer',
			params: { where: { id } },
			returnFields: StripeUserReturnFields,
		} )
	);
	if ( errors ) return { errors };

	const StripeUser = data.data.getStripeCustomer;

	return { StripeUser };
};

/**
 * Get a coupon's details.
 *
 * @param name - Code/name of coupon.
 *
 * @returns {Object} res - { errors, coupon }
 */
API.getCouponDetails = async function( name, timeZone ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getCouponDetails',
			params: { where: { name, timeZone } },
			returnFields: ReducedCouponReturnFields,
		} )
	);
	if ( errors ) return { errors };

	const coupon = data.data.getCouponDetails;

	return { coupon };
};

/**
 * Get a coupon's details.
 *
 * @param couponID - id of coupon to get details for.
 *
 * @returns {Object} res - { errors, coupon }
 */
API.getCouponForSubscription = async function( couponID ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getCouponForSubscription',
			params: { where: { id: couponID } },
			returnFields: ReducedCouponReturnFields,
		} )
	);
	if ( errors ) return { errors };

	const coupon = data.data.getCouponForSubscription;

	return { coupon };
};

/**
 * Registers the use of a coupon in the DB.
 *
 * @param { String } id - id of the coupon being registered.
 * @param { String } timeZone - user's time zone, theoretically
 *
 * @returns {Promise< Object | Error[]>}
 */
API.registerCouponUsage = async function( organizationID, CouponID, timeZone ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'registerCouponUsage',
			params: {
				where: {
					id: organizationID,
				},
				data: {
					coupon: {
						id: CouponID,
					},
					timeZone,
				},
			},
			returnFields: CouponReturnFields,
		} )
	);
	if ( errors ) return { errors };

	const coupon = data.data.registerCouponUsage;

	return { coupon };
};

API.createCustomerStripeBankAccount = function( where, data ) {
	const mutationName = 'createCustomerStripeBankAccount';
	return this.request(
		new Query( {
			type: 'mutation',
			name: mutationName,
			params: {
				where,
				data,
			},
			returnFields: SinglePaymentSourceReturnFields,
		} )
	).then( ( res ) => {
		if ( res.data && res.data.errors ) return { errors: res.data.errors };
		else if ( res.errors ) return { errors: res.errors };

		return {
			source: res.data.data[ mutationName ],
			errors: res.data.errors,
		};
	} );
};

/**
 * Gets all services selectable on the app.
 *
 * @returns {Promise<{services, errors}>}
 */
API.getAllServices = async function() {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getServicesWhere',
			params: { where: { NOT: { id: '' } } },
			returnFields: [ 'id', 'name' ],
		} )
	);
	if ( errors ) return { errors };

	return {
		services: data.data.getServicesWhere,
	};
};

// Begin Events Section

/**
 * Dismisses an event by its ID for the current user.
 *
 * @param { String } eventID - Event of ID to dismiss.
 *
 * @returns { Promise<Boolean> }
 */
API.dismissEvent = async function( eventID ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'dismissEvent',
			params: { where: { id: eventID } },
		} )
	);

	if ( errors ) return { errors };

	const success = data?.data?.dismissEvent;

	return { success };
};

/**
 * Gets events for the dashboard for a given user by their ID
 *
 * @param { String } userID - ID of user
 * @param { Int } skip - Entries to skip for pagination
 *
 * @returns { Promise<{events, moreToLoad}> }
 */
API.getEventsForDashboard = async function( user, skip = 0 ) {
	let eventsNotHandled = [ ...EventsNotHandled ];

	if ( user.userType === 'ClientUser' ) {
		eventsNotHandled = [ ...EventsNotHandled, ...EventsNotHandledForClient ];
	}

	const params = {
		where: {
			consumingUsers: { some: { id: user.id } },
			dismissingUsers: { every: { NOT: { id: user.id } } },
			NOT: { category: { in: eventsNotHandled } },
		},
		take: Globals.itemReturnLimit + 1,
		orderBy: { createdAt: 'desc' },
		skip,
	};

	return await this.getEvents( params );
};

/**
 * Gets events for a planner on behalf of a client
 *
 * @param { String } userID - The client user's user id
 * @param { Int } skip - Entries to skip for pagination
 *
 * @returns { Promise<{events, moreToLoad}> }
 */
API.getEventsOnBehalfOfClient = async function( userID, skip = 0 ) {
	// These client events should not display for any planners viewing their client's tab
	const clientEventsNotForAnyPlanner = [
		'ConnectionRequestDeclinedByPlannerOnBehalfOfClient',
		'ConnectionRequestCancelledByPlannerOnBehalfOfClient',
		'ConnectionPendingFromInviteByClient',
		'InvoiceApproved',
		'ContractViewed',
		'ContractApproved',
		'InstallmentPaymentRequestedByPlanner',
		...EventsNotHandled,
	];

	const params = {
		where: {
			consumingUsers: { some: { id: userID } },
			NOT: { category: { in: clientEventsNotForAnyPlanner } },
		},
		take: Globals.itemReturnLimit + 1,
		orderBy: [ { createdAt: 'desc' } ],
		skip,
	};

	return await this.getEvents( params );
};

/**
 * Gets events between a client and vendor from the client's perspective
 *
 * @param { {
 *   userID: string,
 *   orgID: string,
 *   orgUserID: string,
 *   skip?: number
 * } } arg
 * @returns { Promise<{events, moreToLoad}> }
 */
API.getEventsBetweenClientAndVendor = async function( {
	userID,
	orgID,
	orgUserID,
	skip = 0,
} ) {
	const params = {
		where: {
			consumingUsers: { some: { id: userID } },
			NOT: {
				category: {
					in: [
						'ContactCreatedByClient',
						'ContactCreatedByPlannerOBOClient',
						'ConnectionRequestedByClient',
						'ConnectionRequestedByVendor',
						'ConnectionRequestedByPlannerOnBehalfOfClient',
						...EventsNotHandled,
					],
				},
			},
			OR: [ { vendor: { id: orgID } }, { orgUser: { id: orgUserID } } ],
		},
		take: Globals.itemReturnLimit + 1,
		orderBy: [ { createdAt: 'desc' } ],
		skip,
	};

	return await this.getEvents( params );
};

/**
 * Gets events between a vendor and client from the vendor user's perspective
 *
 * @param { { userID: string, contactID: string, orgID: string, skip?: number, isAdmin: boolean } } props
 *
 * @returns { Promise<{events, moreToLoad}> }
 */
API.getEventsBetweenVendorAndClient = async function( {
	userID,
	contactID,
	orgID,
	skip = 0,
	isAdmin,
} ) {
	const params = {
		where: {
			...( isAdmin ? {} : { consumingUsers: { some: { id: userID } } } ), // only admins can see all events
			vendor: { id: orgID },
			OR: [ { contacts: { some: { id: contactID } } }, { contact: { id: contactID } }, ],
			NOT: {
				category: {
					in: [
						'ConnectionRequestedByClient',
						'ConnectionRequestedByVendor',
						'ConnectionRequestedByPlannerOnBehalfOfClient',
						...EventsNotHandled,
					],
				},
			},
		},
		take: Globals.itemReturnLimit + 1,
		orderBy: [ { createdAt: 'desc' } ],
		skip,
	};

	return await this.getEvents( params );
};

/**
 * Gets events up to the global limit and whether or not there are more events to load
 *
 * @param { Object } params
 * @param { Object } params.where
 * @param { Int } params.first
 * @param { String } params.orderBy
 * @param { Int } params.skip
 *
 * @returns { Promise<{events, moreToLoad}> }
 */
API.getEvents = async function( params ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getEventsWhere',
			params,
			returnFields: EventReturnFields,
		} )
	);

	if ( errors ) return { errors };

	const events = data?.data?.getEventsWhere;

	if ( events ) {
		let moreToLoad = false;
		if ( events.length === Globals.itemReturnLimit + 1 ) {
			events.pop();
			moreToLoad = true;
		}
		return { events, moreToLoad };
	}
};

// End Events Section

/**
 * Assigns a planner to a client user.
 *
 * @param {String} plannerID - Planner user's ID.
 * @param {String} contactID - Client user's ID.
 *
 * @returns {Promise<{ errors: string[] } | { contact: import('./contacts').CONTACT }>}
 */
API.assignMemberToContact = async function( plannerID, contactID ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'assignMemberToContact',
			params: {
				where: { id: contactID },
				data: { id: plannerID },
			},
			returnFields: [
				...ContactScalarFields,
				{
					customer: [
						{
							clientUser: [
								// eslint-disable-next-line array-element-newline
								...ClientUserDetailReturnFields,
								{ assignedPlanner: OrgUserWithClientsReturnFields },
							],
						},
					],
				},
				{ assignedMember: [
					'id',
					'firstName',
					'lastName'
				] },
			],
		} )
	);
	if ( errors ) return { errors };

	const contact = data.data.assignMemberToContact;

	return { contact };
};

/**
 * Get clients for the client index.
 *
 * @param { {
 *   orgUser: Record<string, any>,
 *   limit?: number,
 *   skip?: number,
 *   orderBy?: Record<string, any>,
 *   queryString?: string
 * } } arg
 * @returns {Object} res - { errors, clients, skip, moreToLoad }
 */
API.getClientUsersForOrgUser = async function( {
	orgUser,
	limit,
	skip,
	orderBy = { updatedAt: 'desc' },
	queryString,
} ) {
	let clients = [];

	const where = {
		user: {
			contacts: {
				some: { status: 'Active' },
			},
		},
	};

	if ( queryString ) {
		const queryArr = queryString.split( ' ' );

		const queryReducer = ( constraints, query ) => {
			return [
				...constraints,
				{ firstNameOne_contains: query },
				{ lastNameOne_contains: query },
				{ firstNameTwo_contains: query },
				{ lastNameTwo_contains: query },
			];
		};

		const clientsWhere = {
			OR: queryArr.reduce( queryReducer, [] ),
		};

		where.AND = [
			{
				...clientsWhere,
			},
		];
	}

	if ( orgUser.isAdmin ) {
		where.user.contacts.some.vendor = { id: orgUser.organization.id };
	} else {
		where.user.contacts.some.assignedMember = { id: orgUser.id };
	}

	let orderByParam;
	if ( Array.isArray( orderBy ) ) {
		orderByParam = orderBy;
	} else {
		orderByParam = [ orderBy ];
	}

	const getClientUsersWhere = new Query( {
		type: 'query',
		name: 'getClientUsersWhere',
		params: {
			where,
			take: limit + 1,
			skip,
			orderBy: orderByParam,
		},
		returnFields: [
			'_count',
			{
				clientUsers: [
					...ClientUserReturnFieldsWithPermissions,
					...ProfileImageFields,
					'weddingDate',
					{ assignedPlanner: OrgUserReturnFields },
					{ user: [ { contacts: ContactWithIdentifiersReturnFields } ] },
				],
			},
		],
	} );

	const { data, errors } = await this.request( getClientUsersWhere );

	if ( errors || data.errors ) return { errors: errors || data.errors };

	if ( data.data.getClientUsersWhere.clientUsers ) {
		clients = data.data.getClientUsersWhere.clientUsers;
	}

	let moreToLoad = false;

	if ( clients.length > limit ) {
		clients.pop();
		moreToLoad = true;
	}

	return {
		clients,
		moreToLoad,
		skip: skip + clients.length,
		count: data.data.getClientUsersWhere._count,
	};
};

/**
 * Get the organizations connected to a specific client. Pagination/filters available.
 *
 * @param { {
 *   clientID: string,
 *   where: Record<string, any>,
 *   limit?: number,
 *   skip?: number
 * } }
 * @returns {Promise<Object>} res - { errors, orgs }
 */
API.getClientConnectedOrgs = async function( { clientID, where, limit, skip } ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getOrganizationsWhere',
			params: {
				where: {
					...where,
					contacts: {
						some: {
							customer: {
								clientUser: { id: clientID },
							},
						},
					},
				},
				take: limit,
				skip,
			},
			returnFields: [
				{
					organizations: [
						...OrgReturnFields,
						new Query( {
							name: 'contacts',
							params: { where: { customer: { clientUser: { id: clientID } } } },
							returnFields: [
								...ContactScalarFields,
								{ assignedMember: [
									'id',
									'firstName',
									'lastName'
								] },
							],
						} ),
					],
				},
			],
		} )
	);
	if ( errors ) return { errors };

	const orgs = data.data.getOrganizationsWhere.organizations;

	return { orgs };
};

// Begin Invitation section

/**
 * Get invitation by id.
 *
 * @param {string} id - Invitation ID.
 *
 * @returns {object} res - { errors, invitation }
 */
API.getInvitationByID = async function( id ) {
	const where = { id };
	const { data: inviteData, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getInvitationWhere',
			params: { where },
			returnFields: [ ...InvitationReturnFields ],
		} )
	);

	if ( errors || inviteData.errors )
		return { errors: errors || inviteData.errors };

	return {
		invitation: inviteData.data.getInvitationWhere,
	};
};

/**
 * Get invitations based on search
 *
 * @param { Object } where - see InvitationsWhereCustomInput (inviter ID and invitationType)
 *
 * @returns { Promise<Object> }
 */
API.getPendingInvitations = async function( where, limit, skip ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getPendingInvitations',
			params: {
				where,
				take: limit + 1,
				skip,
			},
			returnFields: [ '_count', { pendingInvitations: InvitationReturnFields } ],
		} )
	);

	if ( errors ) return { errors };

	let invitations = data?.data?.getPendingInvitations?.pendingInvitations;

	if ( !invitations ) {
		invitations = [];
	}

	let moreToLoad = false;

	if ( invitations.length > limit ) {
		invitations.pop();
		moreToLoad = true;
	}

	const newSkip = skip + invitations.length;

	return {
		invitations,
		moreToLoad,
		skip: newSkip,
	};
};

// End Invitation section

/**
 * Request a verification token be created and sent to your email.
 *
 * @param { string } email
 * @param { string } [promoCode]
 *
 * @returns { Promise<{ errors: string[] } | true }> }
 */
API.createVerificationToken = async function( email, promoCode ) {
	const data = {};
	data.email = email;
	if ( promoCode ) {
		data.promoCode = promoCode;
	}
	const response = await this.request(
		new Query( {
			type: 'mutation',
			name: 'createVerificationToken',
			params: { data },
		} )
	);

	if ( 'errors' in response && response.errors ) {
		return { errors: response.errors };
	}

	return true;
};

/**
 * Attempts to verify an email address with a verification token.
 *
 * @param {String} id - Email verification token.
 *
 * @returns {Object} res - { errors, success }
 */
API.verifyEmail = async function( id ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'verifyEmailAccount',
			params: { where: { id } },
		} )
	);
	if ( errors ) return { errors };

	const success = data.data.verifyEmailAccount;

	return { success };
};

/**
 * Gets a verification token from the database.
 *
 * @param {String} id - The id of the token to fetch.
 *
 * @returns {Promise<Object>} res - { errors, verificationToken }
 */
API.getVerificationToken = async function( id ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getVerificationToken',
			params: { where: { id } },
			returnFields: [ 'id', 'email' ],
		} )
	);
	if ( errors ) return { errors };

	const verificationToken = data.data.getVerificationToken;

	return {
		verificationToken,
	};
};

/**
 * Request a resend of a verification email.
 *
 * @returns {Object} res - { success }
 */
API.requestEmailVerification = async function() {
	const { data } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'resendVerificationEmail',
		} )
	);

	const success = data.data && data.data.resendVerificationEmail;
	return { success };
};

/**
 * As a superadmin, force a resend of a verification email.
 *
 * @param {String} email - The email address to send a verification email to.
 *
 * @returns {Promise<{{ success } | { errors }}>}
 */
API.resendVerificationEmailToUser = async function( email ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			params: { email },
			name: 'resendVerificationEmailToUser',
		} )
	);

	if ( errors ) {
		return { errors };
	}

	const success = data.data && data.data.resendVerificationEmailToUser;
	return { success };
};

/**
 * As a superadmin, force a resend of a verification email.
 *
 * @param {String} email - The email of the user to mark as verified.
 *
 * @returns {Promise<{{ user } | { errors }}>}
 */
API.forceEmailVerification = async function( email ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'forceEmailVerification',
			params: { email },
			returnFields: [ ...UserReturnFields, 'isVerified' ],
		} )
	);

	if ( errors ) {
		return { errors };
	}

	const user = data.data.resendVerificationEmailToUser;
	return { user };
};

/**
 * As a superadmin, update a user's email address.
 *
 * @param {String} oldEmail - The email of the user to update.
 * @param {String} newEmail - The users new email address.
 *
 * @returns {Promise<{{ user } | { errors }}>}
 */
API.updateEmailAddressForUser = async function( oldEmail, newEmail ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'updateEmailAddressForUser',
			params: { oldEmail, newEmail },
			returnFields: [ ...UserDetailReturnFields, 'isVerified' ],
		} )
	);

	if ( errors ) {
		return { errors };
	}

	const user = data.data.updateEmailAddressForUser;
	return { user };
};

/**
 * Update a user's email address.
 *
 * @param {string} currentEmailAddress - Current email of the user being updated.
 * @param {string} newEmailAddress - Email to update to.
 * @param {string} password - Password of the currently logged in user.
 *
 * @returns {Object} res - { errors, success }
 */
API.updateEmailAddress = async function(
	currentEmailAddress,
	newEmailAddress,
	password
) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'updateEmailAddress',
			params: {
				where: { currentEmailAddress },
				data: { newEmailAddress, password },
			},
		} )
	);

	if ( errors || data.errors ) return { errors: errors || data.errors };

	if ( data.data.updateEmailAddress ) {
		return { success: true };
	}
};

/**
 * Marks the currently logged in user as having completed on-boarding.
 *
 * @returns {object} res - {success, errors}
 */
API.markUserOnboardingComplete = async function() {
	const res = await this.request(
		new Query( {
			type: 'mutation',
			name: 'markUserOnboardingComplete',
		} )
	);

	if ( res.errors ) {
		return {
			errors: res.errors,
		};
	}

	return { success: res.data.data.markUserOnboardingComplete };
};

/**
 * Get the relevant fields of a client user for on-boarding.
 *
 * @param {String} id - ID of client user.
 *
 * @returns {Promise<{{ clientUser, StripeUser }| { errors }}>}
 */
API.getClientForOnBoarding = async function( id ) {
	const { errors, data } = await this.request(
		new Query( {
			type: 'query',
			name: 'getClientUserWhere',
			params: { where: { id } },
			returnFields: [
				'country',
				...ClientUserDetailReturnFields,
				{ user: [ 'onboardingCompleted', 'acceptedTerms' ] },
			],
		} )
	);

	if ( errors ) return { errors };

	const clientUser = data.data.getClientUserWhere;

	const { StripeUser } = await this.getStripeCustomer( id );

	return {
		clientUser,
		StripeUser,
	};
};

/**
 * Get an anonymous url for an invoice.
 *
 * @param {String} id - Invoice id.
 *
 * @returns {Object} res - {  url }
 */
API.getSharableGuestInvoiceLink = async function( id ) {
	const params = {
		where: { id },
	};
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getSharableGuestInvoiceLink',
			params,
			returnFields: [ 'url' ],
		} )
	);
	if ( errors ) return { errors };

	const { url } = data.data.getSharableGuestInvoiceLink;

	return { url };
};

/**
 * Generates an event requesting a specific client update the event date on their profile
 *
 * @param { String } clientID - the id of the client of whom to request the update
 *
 * @returns { Object } boolean
 */
API.requestClientEventDateChange = async function( clientID ) {
	const params = {
		where: {
			id: clientID,
		},
	};
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'requestClientEventDateChange',
			params,
		} )
	);

	if ( errors ) {
		return { errors };
	}
	const success = data.data.requestClientEventDateChange;
	return { success };
};

/**
 * Wrapper for requesting a client to give planner permissions
 *
 * @param { String } contactId - the id of the contact between the requestor and requestee
 *
 * @returns { Object } boolean
 */
API.requestPlannerPermissions = async function( contactId ) {
	const params = {
		where: {
			id: contactId,
		},
	};
	const { data, errors } = await this.request(
		new Query( {
			type: 'mutation',
			name: 'requestPlannerPermissions',
			params,
		} )
	);

	if ( errors ) {
		return { errors };
	}
	const success = data.data.requestPlannerPermissions;

	return { success };
};

/** @typedef {import('../../blocks/PermissionsModal/PermissionsToggles').PERMISSIONS_SETTINGS} PERMISSIONS_SETTINGS */
/**
 * Assigns vendor permissions for a client by contact
 * @param {string} contactId
 * @param {PERMISSIONS_SETTINGS} permissions
 * @returns {Promise<Error[]|{
 *   plannerOrgHasConnectionPermissions: boolean,
 *   plannerOrgHasDocumentsPermissions: boolean,
 *   plannerOrgCanPayInvoices: boolean,
 *   plannerOrgCanSignContracts: boolean,
 * }>}
 */
API.assignPlannerPermissions = async ( contactId, permissions ) => {
	const { data, errors } = await API.request(
		new Query( {
			type: 'mutation',
			name: 'assignPlannerPermissions',
			params: {
				where: { id: contactId },
				data: permissions,
			},
			returnFields: [
				{
					customer: [
						{
							clientUser: [
								'plannerOrgHasConnectionPermissions',
								'plannerOrgHasDocumentsPermissions',
								'plannerOrgCanPayInvoices',
								'plannerOrgCanSignContracts',
							],
						},
					],
				},
			],
		} )
	);

	if ( errors ) {
		return { errors };
	}

	if ( data ) {
		return data.data.assignPlannerPermissions.customer.clientUser;
	}
};

/**
 * Removes all vendor permissions for a client by clientId
 * @param {string} clientUserId
 * @returns {Promise<boolean|string[]>}
 */
API.removeAllPlannerPermissions = async ( clientUserId ) => {
	const { data, errors } = await API.request(
		new Query( {
			type: 'mutation',
			name: 'removeAllPlannerPermissions',
			params: {
				where: { id: clientUserId },
			},
		} )
	);

	if ( errors ) {
		return { errors };
	}

	if ( data ) {
		return data.data.removeAllPlannerPermissions;
	}
};

/**
 * Gets API version from back-end
 * (but it must be the same as the front-end in order for the query to work at all, right?)
 *
 * @returns {Object} { getApiVersion string || errors Object }
 */
API.getApiVersion = async function() {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getApiVersion',
			returnFields: [
				'version',
				'env',
				'commitId',
				'branch'
			],
		} )
	);

	if ( errors ) {
		return { errors };
	}

	return {
		getApiVersion: data.data.getApiVersion,
	};
};

export default API;
