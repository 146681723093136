import { ContractScalarFields } from './contract';
import { ContactScalarFields } from './contact';
import { UserFieldsForGetContractWhere } from './user';
import { ClientUserFieldsForGetContractWhere } from './clientUser';
import {
	OrgUserScalarFields,
	OrgUserFieldsForGetContractWhere,
} from './orgUser';
import { OrganizationScalarfields } from './organization';
import { ServiceScalarFields } from './service';

const GetContractsWhereFields = [
	'id',
	'title',
	'createdAt',
	'updatedAt',
	'sentAt',
	'dueDate',
	'status',
	'isPastDue',
	'eventDate',
	{
		signatures: [
			{
				customerSignature: [
					{
						contact: [
							'id',
							{ customer: [ 'id ' ] }, // this is just for one Cypress test
							{ vendor: [ 'id' ] },
							{
								assignedMember: [ { user: [ 'id' ] }, { organization: [ 'id', 'name' ] }, ],
							},
							{ vendor: [ 'id', 'name' ] },
						],
					},
				],
			},
		],
	},
	{
		sentByUser: [
			'firstName',
			'lastName',
			{
				user: [ 'id' ],
			},
		],
	},
];

const ProfileImageFields = [
	'image',
	'imageScale',
	'imageRotation',
	'imagePositionX',
	'imagePositionY',
	'originalImage',
];

const OrgReturnFields = [
	'id',
	'image',
	'name',
	'description',
	'orgType',
	'brandedEmails',
	'addressLine1',
	'addressLine2',
	'postalCode',
	'website',
	'phone',
	'city',
	'state',
	{ adminUsers: [ 'id' ] },
	{ users: [ 'id' ] },
	{ services: [ 'name', 'id' ] },
];

const OrgNameReturnFields = [ 'name' ];

const OrgDetailReturnFields = [
	...OrgReturnFields,
	...ProfileImageFields,
	{ defaultOrgUser: [ 'id' ] },
	'name',
	'addressLine1',
	'addressLine2',
	'postalCode',
	'description',
	'website',
	'phone',
	'city',
	'state',
	'helloSignID',
	'country',
	'contactEmail',
	'twitter',
	'instagram',
	'facebook',
	'pinterest',
	'hasStripeVerificationNeeds',
	'cardConvenienceFeePercentage',
	{ services: [ 'name', 'id' ] },
	{ adminUsers: [ 'id' ] },
];

const StripeMetaData = 'metadata { key, value }';

const SubscriptionReturnFields = [ 'stripeSubscriptionID', { subscriptionPlan: [ 'stripePlanID' ] }, ];

const UserReturnFields = [
	'id',
	'userType',
	'notificationPreference'
];

const AuthedUserReturnFields = [
	...UserReturnFields,
	'email',
	'isVerified',
	'onboardingCompleted',
];

const UserDetailReturnFields = [
	...UserReturnFields,
	'email',
	'createdAt',
	'lastSeenAt',
	'notificationPreference',
	'updatedAt',
	'userType',
];

const SuperAdminUserReturnFields = [
	'id',
	'firstName',
	'lastName',
	'image',
	'createdAt',
];

const OrgUserReturnFields = [
	'id',
	'image',
	'phone',
	'firstName',
	'lastName',
	{ organization: OrgReturnFields },
	{ user: UserReturnFields },
];

const OrgUserDetailReturnFields = [
	...OrgUserReturnFields,
	{ user: [
		'id',
		'email',
		'onboardingCompleted',
		'blocked'
	] },
	'phone',
	'image',
	{
		organization: [
			'id',
			{ adminUsers: [ 'id' ] },
			{ services: [ 'name', 'id' ] }
		],
	},
	...ProfileImageFields,
];

const AuthedOrgUserReturnFields = [
	...OrgUserReturnFields,
	'createdAt',
	'phone',
	// relevant for event queries, may not belong here.
	{ assignedClients: [ 'id' ] },
	// used to check admin status
	{
		organization: [
			'id',
			'description',
			'createdAt',
			'hasStripeVerificationNeeds',
			'helloSignID',
			'addressLine1',
			'addressLine2',
			'description',
			'country',
			'contactEmail',
			'city',
			'state',
			'phone',
			'website',
			'twitter',
			'facebook',
			'instagram',
			'pinterest',
			'helloSignID',
			'stripeSubscriptionCustomerID',
			'stripeID',
			'hasStripeVerificationNeeds',
			'invoicesAllowGratuity',
			'automatedRemindersEnabled',
			'canReceivePayments',
			{ rootFolder: [ 'id' ] },
			{ coupon: [ 'name', 'id' ] },
			{ subscription: [ 'status', 'subscriptionPlan { period }' ] },
			{ adminUsers: [ 'id' ] },
			{ groups: [ 'id', 'name' ] },
			{ defaultOrgUser: [
				'id',
				'firstName',
				'lastName'
			] },
		],
	},
	{ user: AuthedUserReturnFields },
];

const ClientUserReturnFields = [
	'id',
	'image',
	'firstNameOne',
	'lastNameOne',
	'firstNameTwo',
	'lastNameTwo',
	{ user: UserReturnFields },
];

const ClientUserReturnFieldsWithPermissions = [
	'id',
	'image',
	'firstNameOne',
	'lastNameOne',
	'firstNameTwo',
	'lastNameTwo',
	'plannerOrgCanPayInvoices',
	'plannerOrgCanSignContracts',
	'plannerOrgHasConnectionPermissions',
	'plannerOrgHasDocumentsPermissions',
	{ user: UserReturnFields },
];

const ClientUserDetailReturnFields = [
	...ClientUserReturnFields,
	...ProfileImageFields,
	'description',
	'venue',
	'twitter',
	'instagram',
	'facebook',
	'pinterest',
	'weddingDate',
	'phone',
	'addressLine1',
	'addressLine2',
	'city',
	'state',
	'postalCode',
	'country',
	'plannerOrgCanPayInvoices',
	'plannerOrgCanSignContracts',
	'plannerOrgHasConnectionPermissions',
	'plannerOrgHasDocumentsPermissions',
	{ user: [
		'id',
		'email',
		'stripeCustomerId'
	] },
	{ assignedPlanner: OrgUserDetailReturnFields },
];

const GuestUserDetailReturnFields = [
	{ user: [
		'id',
		'email',
		'userType',
		'stripeCustomerId'
	] },
];

const ContactWithIdentifiersReturnFields = [
	...ContactScalarFields,
	{
		customer: [
			'id',
			'email',
			'userType',
			{ clientUser: ClientUserDetailReturnFields },
			{ guestUser: GuestUserDetailReturnFields },
		],
	},
	{ assignedMember: OrgUserReturnFields },
	{ vendor: OrgDetailReturnFields },
];

const OrgUserWithClientsReturnFields = [
	...OrgUserDetailReturnFields,
	{
		contacts: [ ...ContactScalarFields, { customer: [ { clientUser: ClientUserReturnFields } ] }, ],
	},
];

const LineItemReturnFields = [
	'id',
	'name',
	'quantity',
	'rate',
	'taxPercentage',
];

const PaymentInstallmentReturnFields = [
	'id',
	'name',
	'amountDue',
	'gratuityPaid',
	'dueDate',
	'status',
	'convenienceFee',
	'amountPaid',
	'amountRefunded',
	'processor',
	'offlinePaymentType',
	'paymentDate',
	'paymentNote',
	'refundDate',
	'refundNote',
	'scheduledPaymentDate',
	'scheduledPaymentMethod',
	'stripePaymentMethod',
];

const InvoiceReturnFields = [
	'id',
	'title',
	'status',
	'isPastDue',
	'approvedByPlanner',
	'uploadAttachment',
	'discountIsPercentage',
	'discountPercentage',
	'discountAmount',
	'eventDate',
	'updatedAt',
	'createdAt',
	'gratuityAmount',
	'sentAt',
	'attachmentUrl',
	'allowsGratuity',
	{ lineItems: LineItemReturnFields },
	{ paymentInstallments: PaymentInstallmentReturnFields },
	{ contact: ContactWithIdentifiersReturnFields },
];

const InvoiceNameReturnFields = [ 'title' ];

const SinglePaymentSourceReturnFields = [
	'id',
	'last4',
	'address_zip',
	'account_holder_name',
	'account_holder_type',
	'name',
	'object',
	'brand',
	'bank_name',
	'exp_month',
	'exp_year',
	'status',
	'country',
	StripeMetaData,
];

const PaymentSourcesReturnFields = [ { data: SinglePaymentSourceReturnFields } ];

const StripeUserReturnFields = [
	'id',
	'default_source',
	{ sources: PaymentSourcesReturnFields },
];

const ContractReturnFields = [
	'dueDate',
	'eventDate',
	'id',
	'isPastDue',
	'sentAt',
	'sentByOrganizationName',
	'status',
	'title',
	{
		signatures: [ { customerSignature: [ { contact: ContactWithIdentifiersReturnFields } ] }, ],
	},
];

const ContractTemplateReturnFields = [
	'id',
	'title',
	{ createdBy: OrgUserReturnFields },
	'createdAt',
	'sourceFileUrl',
	'editUrl',
	'helloSignTemplateID',
	'status',
	'vendorWillSign',
	'isLegacy',
	{ contracts: GetContractsWhereFields },
];

const ContractTemplateNameReturnFields = [ 'title' ];

const ContractDetailReturnFields = [
	...ContractReturnFields,
	{ contractTemplate: [ 'id', 'vendorWillSign' ] },
	'approvedByPlanner',
	'contractSourceFile',
	'executedContractUrl',
	'sentAt',
	'unsignedContractFileUrl',
	'updatedAt',
];

const ContractNameReturnFields = [ { contract: [ 'title' ] } ];

const EmbeddedRequestReturnFields = [ 'id', 'url' ];

const StripeSubscriptionPlanReturnFields = [
	'id',
	'amount',
	'interval',
	'nickname',
];

const StripeSubscriptionReturnFields = [
	'id',
	'status',
	'object',
	'billing',
	'billing_cycle_anchor',
	'cancel_at_period_end',
	'current_period_end',
	'current_period_start',
	'default_source',
	{ plan: StripeSubscriptionPlanReturnFields },
	'start',
	'status',
	'trial_start',
];

const StripeRequirementErrors = [
	'code',
	'reason',
	'requirement'
];

const StripeRequirementsReturnFields = [
	'current_deadline',
	'currently_due',
	'disabled_reason',
	{ errors: StripeRequirementErrors },
	'eventually_due',
	'past_due',
	'pending_verification',
];

const StripeConnectAccountReturnFields = [
	'id',
	'object',
	'country',
	StripeMetaData,
	{ external_accounts: PaymentSourcesReturnFields },
	{ requirements: StripeRequirementsReturnFields },
	{ future_requirements: StripeRequirementsReturnFields },
	'type',
];

const StripeAddressReturnFields = [
	'city',
	'country',
	'line1',
	'line2',
	'postal_code',
	'state',
];

const InvitationReturnFields = [
	'createdAt',
	'id',
	'recipientFirstName',
	'recipientLastName',
	'inviter',
	'invitationType',
	'emailAddress',
];

const StripeCouponReturnFields = [
	'id',
	'object',
	'amount_off',
	'currency',
	'max_redemptions',
	StripeMetaData,
	'name',
	'duration_in_months',
	'percent_off',
	'valid',
	'period',
];

const ReducedCouponReturnFields = [
	'id',
	'name',
	'percent_off',
	'duration_in_months',
	'createdAt',
	'expiresAt',
	'active',
	'stripeID',
];

const CouponReturnFields = [
	'id',
	'name',
	'active'
];

const StripeInvoiceReturnFields = [
	'id', // String!
	'object', // String!
	{ discount: [ { coupon: StripeCouponReturnFields } ] }, // Discount
	'amount_due',
	'description', // String
	'due_date', // Int
	'invoice_pdf', // String
	'paid', // Boolean
	'next_payment_attempt', //Int
	'period_end', // Int
	'period_start', // Int
	'status', // String
	'subscription', // String
	'total', // Int
	'subscription',
	StripeMetaData, // [ReturningMetaData]
];

const EventReturnFields = [
	'category',
	'createdAt',
	'id',
	'message',
	{
		resolvedBy: [ 'id' ],
	},
	{
		actingUser: [
			'id',
			{
				orgUser: [
					'id',
					'firstName',
					'lastName',
					{ user: [ 'id' ] },
					{
						organization: [
							'id',
							'name',
							{
								services: [ 'name' ],
							},
						],
					},
				],
			},
		],
	},
	{
		clientUser: [
			'id',
			'firstNameOne',
			'lastNameOne',
			'firstNameTwo',
			'lastNameTwo',
			'plannerOrgCanPayInvoices',
			'plannerOrgCanSignContracts',
			'plannerOrgHasConnectionPermissions',
			{ user: [ 'id', 'email' ] },
			{
				assignedPlanner: [
					'id',
					{
						organization: [
							'id',
							'name',
							'orgType',
							{ services: [ 'name', 'id' ] },
						],
					},
				],
			},
		],
	},
	{
		guestUser: [ { user: [ 'id', 'email' ] } ],
	},
	{
		vendor: [
			'id',
			'name',
			{ services: [ 'name', 'id' ] }
		],
	},
	{
		orgUser: [
			'id',
			'firstName',
			'lastName',
			{ user: [ 'id', 'email' ] },
			{
				organization: [
					'id',
					'name',
					'orgType',
					{ services: [ 'name', 'id' ] }
				],
			},
		],
	},
	{
		plannerUser: [
			'id',
			'firstName',
			'lastName',
			{
				organization: [
					'id',
					'name',
					'orgType',
					{ services: [ 'name', 'id' ] }
				],
			},
			{ user: [ 'id' ] },
		],
	},
	{
		contacts: [
			'id',
			'status',
			'firstName',
			'lastName',
			{
				customer: [
					'id',
					'email',
					'userType',
					{
						clientUser: [
							'id',
							{
								assignedPlanner: [
									'id',
									{
										organization: [ 'id' ],
									},
								],
							},
						],
					},
				],
			},
			{
				vendor: [
					{
						adminUsers: [ 'id' ],
					},
				],
			},
			{ assignedMember: [ 'id' ] },
			{
				customer: [ 'id' ],
			},
		],
	},
	{
		contact: [
			'id',
			'status',
			'firstName',
			'lastName',
			{
				customer: [
					'id',
					'email',
					'userType',
					{
						clientUser: [
							'id',
							{
								assignedPlanner: [
									'id',
									{
										organization: [ 'id' ],
									},
								],
							},
						],
					},
				],
			},
			{
				vendor: [
					'id',
					{
						adminUsers: [ 'id' ],
					},
				],
			},
			{ assignedMember: [ 'id' ] },
			{
				customer: [ 'id' ],
			},
		],
	},
	{
		invitation: [
			'emailAddress',
			'recipientFirstName',
			'recipientLastName'
		],
	},
	{
		contract: [
			'id',
			'title',
			{
				sentByUser: [ { user: [ 'id' ] } ],
			},
			{
				vendor: [ 'id' ],
			},
			{
				signatures: [
					'type',
					{
						signer: [
							'id',
							{ orgUser: [
								'firstName',
								'lastName',
								{ user: [ 'id' ] }
							] },
						],
					},
					{
						customerSignature: [
							{
								contact: [
									'id',
									{
										vendor: [
											'id',
											'name',
											{ adminUsers: [ 'id' ] }
										],
									},
									{
										customer: [
											'id',
											{
												clientUser: [
													//eslint-disable-next-line array-element-newline
													{ assignedPlanner: [ { organization: [ 'id' ] }, 'id' ] },
													'plannerOrgCanSignContracts',
												],
											},
										],
									},
									{ assignedMember: [ 'id' ] },
								],
							},
						],
					},
				],
			},
		],
	},
	{
		invoice: [
			'id',
			'title',
			'gratuityAmount',
			{
				paymentInstallments: [ 'id', 'name' ],
			},
			{
				contact: [
					'id',
					{
						vendor: [
							'id',
							'name',
							'orgType',
							{ services: [ 'name', 'id' ] }
						],
					},
				],
			},
		],
	},
	{
		document: [
			'id',
			'name',
			'fileSource',
			'fileType',
			{
				customer: [
					'id',
					'userType',
					{
						clientUser: [
							'id',
							'firstNameOne',
							'lastNameOne',
							{
								assignedPlanner: [ 'id', { organization: [ 'id', { services: [ 'id' ] } ] }, ],
							},
						],
					},
					{ orgUser: [
						'id',
						'firstName',
						'lastName'
					] },
				],
			},
			{ organization: [
				'id',
				'name',
				{ services: [ 'id' ] }
			] },
		],
	},
	{
		paymentInstallments: [ 'id', 'name' ],
	},
	'metadata',
	{
		proposal: [
			'id',
			'title',
			{ proposalTemplate: [ 'id' ] }
		],
	},
];

const StripeDOBReturnFields = [
	'day',
	'month',
	'year'
];

const StripeRelationshipReturnFields = [
	'director',
	'executive',
	'owner',
	'percent_ownership',
	'representative',
	'title',
];

const StripeVerificationDocumentReturnFields = [
	'back',
	'details',
	'details_code',
	'front',
];

const StripePersonVerificationReturnFields = [
	{ additional_document: StripeVerificationDocumentReturnFields },
	'details',
	'details_code',
	{ document: StripeVerificationDocumentReturnFields },
	'status',
];

const PersonReturnFields = [
	'id',
	{ address: StripeAddressReturnFields },
	{ dob: StripeDOBReturnFields },
	'first_name',
	'last_name',
	StripeMetaData,
	{ relationship: StripeRelationshipReturnFields },
	{ requirements: StripeRequirementsReturnFields },
	{ verification: StripePersonVerificationReturnFields },
];

const TransactionItemReturnFields = [
	'id',
	'type',
	'date',
	'amount',
	'status'
];

const TransactionHistoryTransactionReturnFields = [
	...TransactionItemReturnFields,
	{ metadata: [ 'key', 'value' ] },
	{
		clientUser: [
			'id',
			'firstNameOne',
			'lastNameOne',
			'firstNameTwo',
			'lastNameTwo',
			{ user: [ 'id', 'email' ] },
		],
	},
	{ guestUser: [ 'id', { user: [ 'id', 'email' ] } ] },
	{ invoice: [
		'id',
		'title',
		'gratuityAmount'
	] },
	{ paymentInstallment: [
		'id',
		'name',
		'amountDue'
	] },
];

const OfflineTransactionHistory = [
	{
		offlinePaymentHistoryItems: [
			'type',
			'amount',
			'id',
			'name',
			'offlinePaymentType',
			'date',
			{
				invoice: [
					'id',
					'title',
					'gratuityAmount',
					{
						clientUser: [
							'id',
							'firstNameOne',
							'lastNameOne',
							'firstNameTwo',
							'lastNameTwo',
						],
					},
				],
			},
		],
	},
];

const EventsNotHandled = [
	'ContractUpdated',
	'ContractReadyForVendorSignature',
	'ContractDeleted',
	'ContractVoided',
	'InvoiceUpdated',
	'InstallmentPaymentSucceeded',
	'InstallmentPaymentDueInThreeDays',
	'InvitationSentByUser',
	'InstallmentScheduledPaymentFailed',
	'ProposalUpdated',
	'ProposalVoided',
	'ProposalDeleted',
];

const EventsNotHandledForClient = [ 'DocumentSharedWithOrg', 'DocumentSharedWithOrgByPlannerOBO', ];

const DocumentReturnFields = [
	'id',
	'createdAt',
	'updatedAt',
	'fileSize',
	'fileSourceID',
	'ownerType',
	'fileUpdatedAt',
	'location',
	'fileType',
	'name',
	'status',
	'fileSource',
	'type',
	{ clientUser: ClientUserDetailReturnFields },
	{ orgUser: OrgUserReturnFields },
	{ organization: OrgReturnFields },
	'ownerType',
	'sharedWithCustomer',
	{ sharedWithContacts: ContactWithIdentifiersReturnFields },
	{
		parentFolder: [
			'id',
			'name',
			{
				parentFolder: [
					'id',
					'name',
					{
						parentFolder: [
							'id',
							'name',
							{
								parentFolder: [
									'id',
									'name',
									{
										parentFolder: [ 'id', 'name' ],
									},
								],
							},
						],
					},
				],
			},
		],
	},
];

const GetDocumentReturnFields = [
	{
		sharedWithContacts: [
			...ContactScalarFields,
			{ customer: [ { clientUser: ClientUserDetailReturnFields } ] },
			{ assignedMember: OrgUserReturnFields },
			{ vendor: OrgDetailReturnFields },
		],
	},
	...DocumentReturnFields,
];

const GetDocumentNameReturnFields = [ 'name' ];

const CreateDocumentReturnFields = [ 'uploadURL', ...DocumentReturnFields ];

const FolderWithoutChildrenReturnFields = [
	'id',
	'createdAt',
	'updatedAt',
	'metadata',
	{
		parentFolder: [ 'id' ],
	},
	'name',
	{ organization: OrgReturnFields },
	'status',
];

const FolderContentsReturnFields = [
	'id',
	'name',
	{ parentFolder: [ 'id' ] },
	{
		documents: [
			'id',
			'name',
			'createdAt',
			'fileSource',
			'fileType',
			'ownerType',
			{ parentFolder: [ 'id' ] },
			{
				clientUser: [
					'id',
					'plannerOrgHasDocumentsPermissions',
					{
						assignedPlanner: [ 'id', { organization: [ 'id', { services: [ 'name', 'id' ] } ] }, ],
					},
				],
			},
			{
				orgUser: [ 'id', { organization: [ 'id', { services: [ 'name', 'id' ] } ] } ],
			},
		],
	},
	{ childFolders: [
		'id',
		'name',
		'createdAt',
		{ parentFolder: [ 'id' ] }
	] },
];

const FolderReturnFields = [
	'id',
	'createdAt',
	'updatedAt',
	'metadata',
	{
		parentFolder: [ 'id', 'name' ],
	},
	{
		childFolders: [ ...FolderWithoutChildrenReturnFields ],
	},
	{
		documents: [ ...DocumentReturnFields ],
	},
	'name',
	{ organization: OrgReturnFields },
	'status',
];

const GetFolderNameReturnFields = [ 'name' ];

const ResourcesReturnFields = [
	'id',
	'fileType',
	'category',
	'description',
	{
		sponsor: [
			'id',
			'logo',
			'name',
			'description',
			'sponsorLink'
		],
	},
	'status',
	'availableUntil',
];
const ResourceDetailReturnFields = [
	'id',
	'fileType',
	'category',
	'description',
	{
		sponsor: [
			'id',
			'logo',
			'name',
			'description',
			'sponsorLink'
		],
	},
	'status',
	'availableUntil',
	'previewDownloadUrl',
];
const ResourceDownloadLinkReturnFields = [ 'id', 'downloadUrl' ];

const ResourceNameReturnFields = [ 'description' ];

export {
	AuthedOrgUserReturnFields,
	AuthedUserReturnFields,
	ClientUserDetailReturnFields,
	ClientUserReturnFieldsWithPermissions,
	ClientUserReturnFields,
	ContactScalarFields,
	ContactWithIdentifiersReturnFields,
	ContractDetailReturnFields,
	ContractNameReturnFields,
	ContractReturnFields,
	ContractTemplateNameReturnFields,
	ContractTemplateReturnFields,
	CouponReturnFields,
	CreateDocumentReturnFields,
	DocumentReturnFields,
	EmbeddedRequestReturnFields,
	EventReturnFields,
	EventsNotHandled,
	EventsNotHandledForClient,
	FolderContentsReturnFields,
	FolderReturnFields,
	FolderWithoutChildrenReturnFields,
	GetFolderNameReturnFields,
	InvitationReturnFields,
	InvoiceNameReturnFields,
	InvoiceReturnFields,
	LineItemReturnFields,
	OfflineTransactionHistory,
	OrgDetailReturnFields,
	OrgReturnFields,
	OrganizationScalarfields,
	OrgUserDetailReturnFields,
	OrgNameReturnFields,
	OrgUserReturnFields,
	OrgUserWithClientsReturnFields,
	PaymentInstallmentReturnFields,
	PaymentSourcesReturnFields,
	PersonReturnFields,
	ProfileImageFields,
	ResourcesReturnFields,
	ResourceDetailReturnFields,
	ResourceDownloadLinkReturnFields,
	ResourceNameReturnFields,
	SinglePaymentSourceReturnFields,
	StripeAddressReturnFields,
	StripeConnectAccountReturnFields,
	StripeCouponReturnFields,
	StripeDOBReturnFields,
	StripeInvoiceReturnFields,
	StripeMetaData,
	StripePersonVerificationReturnFields,
	StripeRelationshipReturnFields,
	StripeRequirementsReturnFields,
	StripeSubscriptionReturnFields,
	StripeUserReturnFields,
	StripeVerificationDocumentReturnFields,
	SubscriptionReturnFields,
	SuperAdminUserReturnFields,
	TransactionHistoryTransactionReturnFields,
	TransactionItemReturnFields,
	UserDetailReturnFields,
	UserReturnFields,
	GetDocumentReturnFields,
	GetDocumentNameReturnFields,
	ReducedCouponReturnFields,
	GuestUserDetailReturnFields,
	ContractScalarFields,
	UserFieldsForGetContractWhere,
	ClientUserFieldsForGetContractWhere,
	OrgUserScalarFields,
	OrgUserFieldsForGetContractWhere,
	ServiceScalarFields,
	GetContractsWhereFields,
};
