import * as Types from '../../../../types/generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InquiriesConfigurationFieldsInquiryFormFragment = { __typename?: 'InquiryForm', instructions?: string | null, fields?: Array<{ __typename?: 'InquiryFormField', order: number, type: Types.InquiryFormFieldType, label: string, required: boolean, services?: any | null, multiple?: boolean | null }> | null };

export type InquiriesConfigurationFields_CreateInquiryFormMutationVariables = Types.Exact<{
  data: Types.InquiryFormDataInput;
}>;


export type InquiriesConfigurationFields_CreateInquiryFormMutation = { __typename?: 'Mutation', createInquiryForm: { __typename?: 'InquiryForm', instructions?: string | null, fields?: Array<{ __typename?: 'InquiryFormField', order: number, type: Types.InquiryFormFieldType, label: string, required: boolean, services?: any | null, multiple?: boolean | null }> | null } };

export type InquiriesConfigurationFields_UpdateInquiryFormMutationVariables = Types.Exact<{
  data: Types.InquiryFormDataInput;
}>;


export type InquiriesConfigurationFields_UpdateInquiryFormMutation = { __typename?: 'Mutation', updateInquiryForm: { __typename?: 'InquiryForm', instructions?: string | null, fields?: Array<{ __typename?: 'InquiryFormField', order: number, type: Types.InquiryFormFieldType, label: string, required: boolean, services?: any | null, multiple?: boolean | null }> | null } };

export const InquiriesConfigurationFieldsInquiryFormFragmentDoc = gql`
    fragment inquiriesConfigurationFieldsInquiryForm on InquiryForm {
  instructions
  fields {
    order
    type
    label
    required
    services
    multiple
  }
}
    `;
export const InquiriesConfigurationFields_CreateInquiryFormDocument = gql`
    mutation InquiriesConfigurationFields_CreateInquiryForm($data: InquiryFormDataInput!) {
  createInquiryForm(data: $data) {
    ...inquiriesConfigurationFieldsInquiryForm
  }
}
    ${InquiriesConfigurationFieldsInquiryFormFragmentDoc}`;
export type InquiriesConfigurationFields_CreateInquiryFormMutationFn = Apollo.MutationFunction<InquiriesConfigurationFields_CreateInquiryFormMutation, InquiriesConfigurationFields_CreateInquiryFormMutationVariables>;

/**
 * __useInquiriesConfigurationFields_CreateInquiryFormMutation__
 *
 * To run a mutation, you first call `useInquiriesConfigurationFields_CreateInquiryFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInquiriesConfigurationFields_CreateInquiryFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inquiriesConfigurationFieldsCreateInquiryFormMutation, { data, loading, error }] = useInquiriesConfigurationFields_CreateInquiryFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInquiriesConfigurationFields_CreateInquiryFormMutation(baseOptions?: Apollo.MutationHookOptions<InquiriesConfigurationFields_CreateInquiryFormMutation, InquiriesConfigurationFields_CreateInquiryFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InquiriesConfigurationFields_CreateInquiryFormMutation, InquiriesConfigurationFields_CreateInquiryFormMutationVariables>(InquiriesConfigurationFields_CreateInquiryFormDocument, options);
      }
export type InquiriesConfigurationFields_CreateInquiryFormMutationHookResult = ReturnType<typeof useInquiriesConfigurationFields_CreateInquiryFormMutation>;
export type InquiriesConfigurationFields_CreateInquiryFormMutationResult = Apollo.MutationResult<InquiriesConfigurationFields_CreateInquiryFormMutation>;
export type InquiriesConfigurationFields_CreateInquiryFormMutationOptions = Apollo.BaseMutationOptions<InquiriesConfigurationFields_CreateInquiryFormMutation, InquiriesConfigurationFields_CreateInquiryFormMutationVariables>;
export const InquiriesConfigurationFields_UpdateInquiryFormDocument = gql`
    mutation InquiriesConfigurationFields_UpdateInquiryForm($data: InquiryFormDataInput!) {
  updateInquiryForm(data: $data) {
    ...inquiriesConfigurationFieldsInquiryForm
  }
}
    ${InquiriesConfigurationFieldsInquiryFormFragmentDoc}`;
export type InquiriesConfigurationFields_UpdateInquiryFormMutationFn = Apollo.MutationFunction<InquiriesConfigurationFields_UpdateInquiryFormMutation, InquiriesConfigurationFields_UpdateInquiryFormMutationVariables>;

/**
 * __useInquiriesConfigurationFields_UpdateInquiryFormMutation__
 *
 * To run a mutation, you first call `useInquiriesConfigurationFields_UpdateInquiryFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInquiriesConfigurationFields_UpdateInquiryFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inquiriesConfigurationFieldsUpdateInquiryFormMutation, { data, loading, error }] = useInquiriesConfigurationFields_UpdateInquiryFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInquiriesConfigurationFields_UpdateInquiryFormMutation(baseOptions?: Apollo.MutationHookOptions<InquiriesConfigurationFields_UpdateInquiryFormMutation, InquiriesConfigurationFields_UpdateInquiryFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InquiriesConfigurationFields_UpdateInquiryFormMutation, InquiriesConfigurationFields_UpdateInquiryFormMutationVariables>(InquiriesConfigurationFields_UpdateInquiryFormDocument, options);
      }
export type InquiriesConfigurationFields_UpdateInquiryFormMutationHookResult = ReturnType<typeof useInquiriesConfigurationFields_UpdateInquiryFormMutation>;
export type InquiriesConfigurationFields_UpdateInquiryFormMutationResult = Apollo.MutationResult<InquiriesConfigurationFields_UpdateInquiryFormMutation>;
export type InquiriesConfigurationFields_UpdateInquiryFormMutationOptions = Apollo.BaseMutationOptions<InquiriesConfigurationFields_UpdateInquiryFormMutation, InquiriesConfigurationFields_UpdateInquiryFormMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    