import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import MuiButton from '../../../mui/MuiButton';
import { stringToClipboard } from '../../QuickPaymentsModal/helpers';
import styles from './InquiriesPublishForm.module.scss';
import { isDevelopment } from '../../../lib/constants';
import getBackendUrl, {
	EndpointNames,
} from '../../../lib/API/request/serviceMap';
import { gql } from '@apollo/client';
import {
	useInquiriesPublishForm_GetInquiryFormApiKeyQuery,
	useInquiriesPublishForm_GetGlobalVariablesQuery,
} from './__generated__';
import { showError, showSuccess } from '../../Toast';
import MuiSpinner from '../../../mui/MuiSpinner';
import ExtendedLink from '../../ExtendedLink/ExtendedLink';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getGlobalVariablesQuery = gql`
	query InquiriesPublishForm_getGlobalVariables {
		getGlobalVariables {
			InquiryFormSupportUrl
		}
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getInquiryFormApiKeyQuery = gql`
	query InquiriesPublishForm_GetInquiryFormApiKey {
		getInquiryFormApiKey
	}
`;

export type InquiriesPublishFormProps = {
	organizationId: string;
};

export const InquiriesPublishForm: React.FC<InquiriesPublishFormProps> = ( {
	organizationId,
} ) => {
	const {
		data: apiKeyData,
		error: apiKeyError,
		loading: apiKeyLoading,
	} = useInquiriesPublishForm_GetInquiryFormApiKeyQuery();
	const {
		data: urlData,
		error: urlError,
		loading: urlLoading,
	} = useInquiriesPublishForm_GetGlobalVariablesQuery();

	useEffect( () => {
		if ( apiKeyError ) {
			showError( apiKeyError );
		}
		if ( urlError ) {
			showError( urlError );
		}
	}, [ apiKeyError, urlError ] );

	const apiKey = apiKeyData?.getInquiryFormApiKey;
	const apiURL = getBackendUrl( EndpointNames.Query );

	const copy = `<div id="inquiry-form" ${
		isDevelopment ? `data-api="${ apiURL }"` : ''
	} data-api-key="${ apiKey }" data-organization-id="${ organizationId }"></div>\n<script>var s=document.createElement( 'script' );s.setAttribute('src','${ apiURL.replace(
		'query',
		'inquiry-form'
	) }/index.js?cb='+(new Date()).getTime());document.body.appendChild(s)</script>`;
	const copyToClipboard = async () => {
		await stringToClipboard( () => Promise.resolve( copy ) );
		showSuccess( 'Form saved and code copied to clipboard' );
	};

	return (
		<Box className={ styles.container }>
			{ apiKeyLoading ? (
				<Box className={ styles.loading }>
					<MuiSpinner />
				</Box>
			) : null }
			<Typography variant='h6'>Publish inquiry form</Typography>
			<Typography>Your inquiry form is ready to go.</Typography>
			<Typography>
				Copy the code and embed it in your website. You can find more detailed
				information and instructions{ ' ' }
				<ExtendedLink
					className='legacyLink'
					// the loading state will disable in the case of href being nothing
					href={ urlData?.getGlobalVariables.InquiryFormSupportUrl || '' }
					target='_blank'
					rel='noreferrer'
					loading={ urlLoading }
				>
					here
				</ExtendedLink>
				.
			</Typography>
			<Typography>
				Once the RPC form is live on your website all future updates will
				automatically populate on save.
			</Typography>
			<Box className={ styles.code }>
				{ copy.split( '\n' )[ 0 ] }
				<br />
				{ copy.split( '\n' )[ 1 ] }
			</Box>
			<MuiButton onClick={ copyToClipboard } className={ styles.button }>
				save and copy code
			</MuiButton>
		</Box>
	);
};
