import { Box, Divider, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MuiColorInput } from 'mui-color-input';
import dynamic from 'next/dynamic';
const FontPicker = dynamic( () => import( './FontPicker' ), {
	ssr: false,
} );
import rpcShared, { RPCShared } from '@rockpapercoin/rpc-shared';
import styles from './InquiriesStylingForm.module.scss';
import { CornerSelector } from './CornerSelector';
import { gql, DocumentNode } from '@apollo/client';
import {
	InquiryFormDataInput,
	InquiryFormStyleType,
} from '../../../types/generated';
import {
	InquiriesStylingFormInquiryFormFragment,
	useInquiriesStylingForm_UpdateInquiryFormMutation,
} from './__generated__';
import MuiSpinner from '../../../mui/MuiSpinner';
import Script from 'next/script';
import { showError } from '../../Toast';
import { debounce } from 'lodash';
import getBackendUrl, {
	EndpointNames,
} from '../../../lib/API/request/serviceMap';

const families = [
	'Barlow',
	'Baskervville SC',
	'Comic Neue',
	'Cormorant Garamond',
	'Dancing Script',
	'Darker Grotesque',
	'EB Garamond',
	'Gentium Plus',
	'Great Vibes',
	'Gruppo',
	'Italianno',
	'Libre Baskerville',
	'Lora',
	'Maven Pro',
	'Merriweather',
	'Open Sans',
	'Parisienne',
	'Patua One',
	'Playfair Display',
	'Playwrite US Modern',
	'Poiret One',
	'Poppins',
	'PT Sans',
	'PT Serif',
	'Public Sans',
	'Quicksand',
	'Raleway',
	'Reenie Beanie',
	'Roboto',
	'Roboto Serif',
	'Sacramento',
	'Sanchez',
	'Shadows Into Light',
	'Tenor Sans',
	'The Nautigal',
];

const inquiriesStylingFormInquiryFormFragment = gql`
	fragment inquiriesStylingFormInquiryForm on InquiryForm {
		instructions
		fields {
			order
			type
			label
			required
			services
			multiple
		}
		styles {
			type
			value
		}
	}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const updateInquiryFormMutation = gql`
	mutation InquiriesStylingForm_UpdateInquiryForm(
		$data: InquiryFormDataInput!
	) {
		updateInquiryForm(data: $data) {
			...inquiriesStylingFormInquiryForm
		}
	}
	${ inquiriesStylingFormInquiryFormFragment }
`;

type Styles = RPCShared.Inquiries.Styles;
type Colors = RPCShared.Inquiries.Colors;

const getLabelById = ( type: keyof Styles ) => {
	if ( type === InquiryFormStyleType.FontFamily ) {
		return 'Font';
	} else if ( type === InquiryFormStyleType.Instructions ) {
		return 'Instructions';
	} else if ( type === InquiryFormStyleType.FieldLabel ) {
		return 'Field label';
	} else if ( type === InquiryFormStyleType.FieldOutline ) {
		return 'Field outline';
	} else if ( type === InquiryFormStyleType.FieldInputText ) {
		return 'Field Input text';
	} else if ( type === InquiryFormStyleType.ButtonFill ) {
		return 'Button fill';
	} else if ( type === InquiryFormStyleType.ButtonText ) {
		return 'Button text';
	} else if ( type === InquiryFormStyleType.Corners ) {
		return 'Corners';
	}
};

export type InquiriesStylingFormProps = {
	googleFontsApiKey: string;
	inquiryForm?: InquiriesStylingFormInquiryFormFragment | null;
	loading: boolean;
};

type InquiriesStylingFormPropsWithFragments =
	React.FC<InquiriesStylingFormProps> & {
		fragments: {
			inquiriesStylingFormInquiryFormFragment: DocumentNode;
		};
	};

export const InquiriesStylingForm: InquiriesStylingFormPropsWithFragments = ( {
	googleFontsApiKey,
	inquiryForm,
	loading: propsLoading,
} ) => {
	const [ fontFamily, setFontFamily ] = useState( 'Open Sans' );
	const [ colors, setColors ] = useState<Colors | undefined>();
	const [ corners, setCorners ] = useState<Styles['corners']>( 'rounded' );

	const disableMutationRef = useRef( false );

	useEffect( () => {
		disableMutationRef.current = true;
		if ( inquiryForm ) {
			const styles =
				rpcShared.inquiries.stylesSupplementedWithDefaultsAsDictionary(
					inquiryForm.styles
				);
			setFontFamily( styles[ InquiryFormStyleType.FontFamily ] );
			setColors( rpcShared.inquiries.getColorsFromStylesDictionary( styles ) );
			setCorners( styles[ InquiryFormStyleType.Corners ] );
		}
	}, [ inquiryForm, inquiryForm?.styles ] );

	const [ updateInquiryForm, { loading: updateInquiryLoading } ] =
		useInquiriesStylingForm_UpdateInquiryFormMutation( {
			refetchQueries: [ 'InquiriesConfiguration_GetInquiryForm' ],
		} );
	const loading = propsLoading || updateInquiryLoading;

	const mutate = useCallback(
		debounce( async ( styles: InquiryFormDataInput['styles'] ) => {
			const response = await updateInquiryForm( {
				variables: {
					data: {
						styles,
					},
				},
			} );
			if ( response.errors ) {
				response.errors.forEach( ( error ) => showError( error ) );
			}
		}, 250 ),
		[ updateInquiryForm ]
	);

	useEffect( () => {
		if ( disableMutationRef.current ) {
			disableMutationRef.current = false;
		} else {
			const styles = [
				{ type: InquiryFormStyleType.FontFamily, value: fontFamily },
				...( colors
					? Object.entries( colors ).reduce(
						( acc, [ type, value ] ) => [
							...acc,
							{ type, value } as {
								type: InquiryFormStyleType;
								value: string;
							},
						],
						[] as { type: InquiryFormStyleType; value: string }[]
					  )
					: [] ),
				{ type: InquiryFormStyleType.Corners, value: corners },
			];
			mutate( styles );
		}
	}, [
		fontFamily,
		colors,
		corners,
		mutate
	] );

	if ( !inquiryForm || !colors ) {
		return (
			<Box className={ styles.container }>
				<Box className={ styles.loading }>
					<MuiSpinner />
				</Box>
			</Box>
		);
	}

	const stringifiedConfiguration = JSON.stringify(
		rpcShared.inquiries.inquiryFormToConfiguration( inquiryForm )
	);

	return (
		<Box className={ styles.container }>
			{ loading || !inquiryForm ? (
				<Box className={ styles.loading }>
					<MuiSpinner />
				</Box>
			) : null }
			<Box className={ styles.column } data-cy='style'>
				<Typography variant='h6'>Style</Typography>
				<FontPicker
					apiKey={ googleFontsApiKey }
					value={ fontFamily }
					onChange={ ( value ) => setFontFamily( value.family ) }
					families={ families }
					sort='alphabet'
					data-cy='fontFamily'
				/>
				{ Object.entries( colors ).map( ( [ key, value ] ) => (
					<MuiColorInput
						key={ key }
						label={ getLabelById( key as keyof typeof colors ) }
						format='hex'
						value={ value }
						inputProps={ { 'data-cy': key } }
						onChange={ ( value ) =>
							setColors( ( prevState ) =>
								prevState ? { ...prevState, [ key ]: value } : prevState
							)
						}
						isAlphaHidden
					/>
				) ) }
				<CornerSelector
					value={ corners }
					onChange={ ( e ) => setCorners( e.target.value as typeof corners ) }
					data-cy='corners'
				/>
			</Box>
			<Divider orientation='vertical' />
			<Box className={ styles.column } data-cy='preview'>
				<Typography variant='h6'>Preview</Typography>
				<Box
					id='inquiry-form'
					data-configuration={ stringifiedConfiguration }
				></Box>
				<Script
					strategy='lazyOnload'
					src={ `${ getBackendUrl( EndpointNames.Query ).replace(
						'query',
						'inquiry-form'
					) }/index.js?cb=${ new Date().getTime() }` }
				/>
			</Box>
		</Box>
	);
};

InquiriesStylingForm.fragments = {
	inquiriesStylingFormInquiryFormFragment,
};
